<template>
    <section class="section">
        <div class="columns is-centered is-vcentered">
            <div
                v-if="(results.MRData.total > 0)"
                class="column is-four-fifths has-background-secondary is-paddingless"
            >
                <div class="columns is-marginless is-centered">
                    <div class="column is-one-half has-background-primary has-text-left is-standings">
                        {{ this.$route.params.season }} {{ results.MRData.RaceTable.Races[0].raceName }} - Qualifying
                    </div>
                    <ResultsDetailNavigation
                        :season="this.$route.params.season"
                        :race-i-d="(parseInt(results.MRData.RaceTable.round))"
                        :session-type="'qualifying'"
                    />
                </div>
                <div class="columns is-marginless is-mobile">
                    <div
                        class="column is-position-box is-1 is-paddingless has-text-centered"
                        :style="{ width: '30px'}"
                    >
                        #
                    </div>
                    <div class="column is-1 is-spacer is-paddingless is-padded-right">
                        &nbsp;
                    </div>
                    <div class="column is-paddingless is-flag is-padded-right">
                        Driver
                    </div>
                    <div class="column is-paddingless is-flag">
                        Constructor
                    </div>
                    <div class="column is-2 is-paddingless has-text-right is-padded-right">
                        <span v-if="(parseInt(this.$route.params.season) > 2004)">Q1</span>
                        <span v-else>Lap Time</span>
                    </div>
                    <div
                        v-if="(parseInt(this.$route.params.season) > 2004)"
                        class="column is-2 is-paddingless has-text-right is-padded-right"
                    >
                        Q2
                    </div>
                    <div
                        v-if="(parseInt(this.$route.params.season) > 2006)"
                        class="column is-2 is-paddingless has-text-right is-padded-right"
                    >
                        Q3
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full is-gapless">
                        <div
                            v-for="(result, index) in results.MRData.RaceTable.Races[0].QualifyingResults"
                            :key="index"
                            class="columns is-standings is-mobile"
                            :class="{'has-background-primary': index % 2 === 0, 'has-background-secondary': index % 2 !== 0 }"
                        >
                            <div
                                class="column is-position-box is-1 is-paddingless has-text-centered"
                                :style="{ width: '30px'}"
                            >
                                {{ result.position }}
                            </div>
                            <div
                                class="column is-1 is-paddingless is-flag has-text-right"
                                :style="{ width: '30px'}"
                            >
                                <img
                                    :src="'../../../img/FlagKit/drivers/' + result.Driver.nationality + '.svg'"
                                    :alt="result.Driver.nationality"
                                >
                            </div>
                            <div class="column is-paddingless is-padded-left">
                                {{ result.Driver.givenName }} {{ result.Driver.familyName }}
                            </div>
                            <div class="column is-paddingless is-padded-left">
                                {{ result.Constructor.name }}
                            </div>
                            <div class="column is-2 is-paddingless has-text-right is-padded-right">
                                <span v-if="result.Q1">{{ result.Q1 }}</span>
                                <span v-else>&nbsp;</span>
                            </div>
                            <div
                                v-if="(parseInt(results.MRData.RaceTable.Races[0].season) > 2004)"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                <span v-if="result.Q2">{{ result.Q2 }}</span>
                                <span v-else>&nbsp;</span>
                            </div>
                            <div
                                v-if="(parseInt(results.MRData.RaceTable.Races[0].season) > 2005)"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                <span v-if="result.Q3">{{ result.Q3 }}</span>
                                <span v-else>&nbsp;</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="columns is-centered is-multiline"
            >
                <div class="column is-full has-background-primary">
                    FORMULA 1 {{ this.$route.params.season }} SEASON - ROUND {{ results.MRData.RaceTable.round }}
                </div>
                <div class="column is-full has-background-secondary is-centered">
                    <span v-if="this.$route.params.season > new Date().getFullYear()">Future race</span>
                    <span v-else-if="this.$route.params.season < 2003">Sorry, Qualifying information is not available for seasons prior to 2003.</span>
                    <span v-else>Sorry, information for the current race is not available at this time</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ResultsDetailNavigation from '@/components/ResultsDetailNavigation'
export default {
    components: {
        ResultsDetailNavigation
    },
    computed: {
        results () {
            return this.$store.getters.selectedQuali
        }
    },
    mounted () {
        this.$store.dispatch('getSelectedQuali', { season: this.$route.params.season, raceID: this.$route.params.raceID })
    }
}
</script>
