<template>
    <div class="column">
        <div class="columns is-marginless is-centered">
            <div class="column is-full has-text-centered is-standings has-background-secondary">
                FORMULA 1 {{ this.$route.params.season }} SEASON - WORLD DRIVERS CHAMPIONSHIP
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-full is-centered is-gapless">
                <div
                    v-for="(driver, index) in drivers.MRData.StandingsTable.StandingsLists[0].DriverStandings"
                    :key="index"
                    class="columns is-standings is-mobile has-background-primary"
                >
                    <div
                        v-if="driver.positionText === 'D'"
                        class="column is-position-box is-1 is-paddingless has-text-centered"
                    >
                        DSQ
                    </div>
                    <div
                        v-else
                        class="column is-position-box is-1 is-paddingless has-text-centered"
                    >
                        {{ driver.position }}
                    </div>
                    <div class="column is-1 is-paddingless is-flag has-text-right">
                        <img
                            :src="'../../img/FlagKit/drivers/' + driver.Driver.nationality + '.svg'"
                            :alt="driver.Driver.nationality"
                        >
                    </div>
                    <div class="column is-paddingless is-padded-left">
                        {{ driver.Driver.givenName }} {{ driver.Driver.familyName }}
                    </div>
                    <div class="column is-1 is-paddingless">
                        {{ driver.points }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        season: {
            type: String,
            default: '2019'
        },
        showAll: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        drivers () {
            return this.$store.getters.driversStandings
        },
        results () {
            return this.$route.params.season
        },
        showResults () {
            return this.$props.showAll
        }
    },
    mounted () {
        this.$store.dispatch('getDriversStandings', this.$props.season)
        this.$store.dispatch('getSeasonResults', this.$props.season)
    }
}
</script>
