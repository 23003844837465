<template>
    <section class="section">
        <div class="columns is-centered is-vcentered">
            <div
                v-if="(results.MRData.RaceTable.Races.length > 0)"
                class="column is-four-fifths has-background-secondary is-paddingless"
            >
                <div class="columns is-marginless is-centered">
                    <div class="column is-one-half has-background-primary has-text-left is-standings">
                        {{ this.$route.params.season }} {{ results.MRData.RaceTable.Races[0].raceName }} - Race Classification
                    </div>
                    <ResultsDetailNavigation
                        :season="this.$route.params.season"
                        :race-i-d="(parseInt(results.MRData.RaceTable.round))"
                        :session-type="'results'"
                    />
                </div>
                <div class="columns is-marginless is-mobile">
                    <div
                        class="column is-position-box is-1 is-paddingless has-text-centered"
                        :style="{ width: '30px'}"
                    >
                        #
                    </div>

                    <div class="column is-1 is-spacer is-paddingless is-padded-right">
                        &nbsp;
                    </div>
                    <div class="column is-paddingless is-flag is-padded-right">
                        Driver
                    </div>
                    <div class="column is-paddingless is-flag">
                        Constructor
                    </div>
                    <div class="column is-1 is-paddingless has-text-right is-padded-right">
                        Laps
                    </div>
                    <div class="column is-2 is-paddingless has-text-right is-padded-right">
                        Time
                    </div>
                    <div class="column is-1 is-paddingless has-text-right is-padded-right">
                        Grid
                    </div>
                    <div class="column is-1 is-paddingless has-text-right is-padded-right">
                        Points
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full is-gapless">
                        <div
                            v-for="(result, index) in results.MRData.RaceTable.Races[0].Results"
                            :key="index"
                            class="columns is-standings is-mobile"
                            :class="{'has-background-primary': index % 2 === 0, 'has-background-secondary': index % 2 !== 0 }"
                        >
                            <div
                                v-if="result.positionText == 'R' || result.positionText == 'F' || result.positionText == 'D' || result.positionText == 'W' || result.positionText == 'E' || result.positionText == 'N'"
                                class="column is-position-box is-1 is-paddingless has-text-centered"
                                :style="{ width: '30px'}"
                            >
                                &nbsp;
                            </div>
                            <div
                                v-else
                                class="column is-position-box is-1 is-paddingless has-text-centered"
                                :style="{ width: '30px'}"
                            >
                                {{ result.positionText }}
                            </div>
                            <div
                                class="column is-1 is-paddingless is-flag has-text-right"
                                :style="{ width: '30px'}"
                            >
                                <img
                                    :src="'../../img/FlagKit/drivers/' + result.Driver.nationality + '.svg'"
                                    :alt="result.Driver.nationality"
                                >
                            </div>
                            <div class="column is-paddingless is-padded-left">
                                {{ result.Driver.givenName }} {{ result.Driver.familyName }}
                            </div>
                            <div class="column is-paddingless is-padded-left">
                                {{ result.Constructor.name }}
                            </div>
                            <div class="column is-1 is-paddingless has-text-right is-padded-right is-laps">
                                {{ result.laps }}
                            </div>
                            <div
                                v-if="result.positionText == 'R'"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                {{ result.status }}
                            </div>
                            <div
                                v-else-if="result.positionText == 'D'"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                DSQ
                            </div>
                            <div
                                v-else-if="result.positionText == 'E'"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                Excluded
                            </div>
                            <div
                                v-else-if="result.positionText == 'W'"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                Withdrew
                            </div>
                            <div
                                v-else-if="result.positionText == 'F'"
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                DNQ
                            </div>
                            <div
                                v-else
                                class="column is-2 is-paddingless has-text-right is-padded-right"
                            >
                                <span v-if="result.Time">{{ result.Time.time }}</span>
                                <span v-else>+{{ results.MRData.RaceTable.Races[0].Results[0].laps - result.laps }} Laps</span>
                            </div>
                            <div class="column is-1 is-paddingless has-text-right is-padded-right is-grid">
                                {{ result.grid }}
                            </div>
                            <div
                                v-if="result.points"
                                class="column is-1 is-paddingless has-text-right is-padded-right"
                            >
                                {{ result.points }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="column is-four-fifths has-background-secondary is-paddingless"
            >
                <div class="columns">
                    <div class="column is-one-half has-background-primary">
                        FORMULA 1 {{ this.$route.params.season }} SEASON - ROUND {{ results.MRData.RaceTable.round }}
                    </div>
                    <ResultsDetailNavigation
                        :season="this.$route.params.season"
                        :race-i-d="(parseInt(results.MRData.RaceTable.round))"
                        :session-type="'results'"
                    />
                </div>
                <div class="columns">
                    <div class="column is-full has-background-secondary is-centered">
                        <span v-if="this.$route.params.season > new Date().getFullYear()">Sorry, information for the current race is not available at this time</span>
                        <span v-else>Sorry, information for the current race is not available at this time</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ResultsDetailNavigation from '@/components/ResultsDetailNavigation'
export default {
    components: {
        ResultsDetailNavigation
    },
    computed: {
        results () {
            return this.$store.getters.selectedRace
        }
    },
    mounted () {
        this.$store.dispatch('getSelectedRace', { season: this.$route.params.season, raceID: this.$route.params.raceID })
    }
}
</script>
