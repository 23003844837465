<template>
    <div class="column has-background-primary">
        <div class="columns is-gapless is-mobile">
            <div class="column is-paddingless has-text-centered">
                <span
                    v-if="(parseInt(this.$props.raceID - 1) === 0)"
                    class="icon is-size-4 has-text-dark"
                >
                    <i class="fas fa-chevron-circle-left" />
                </span>
                <span
                    v-else-if="(parseInt(this.$props.raceID) === 0)"
                    class="icon is-size-4 has-text-dark"
                >
                    <i class="fas fa-chevron-circle-left" />
                </span>
                <a
                    v-else
                    class="has-text-white"
                    :href="'/' + this.$props.sessionType + '/' + this.$route.params.season + '/' + (parseInt(this.$props.raceID) - 1)"
                    title="Previous Race"
                >
                    <span class="icon is-size-4"><i class="fas fa-chevron-circle-left" /></span>
                </a>
            </div>
            <div class="column is-paddingless has-text-centered">
                <a
                    href="/"
                    class="has-text-white"
                    title="Home"
                >
                    <span class="icon is-size-4"><i class="fas fa-home" /></span>
                </a>
            </div>
            <div
                v-if="(this.$props.sessionType === 'qualifying')"
                class="column is-paddingless has-text-centered"
            >
                <a
                    class="has-text-white"
                    :href="'/results/' + this.$route.params.season + '/' + (parseInt(this.$props.raceID))"
                    title="Race Results"
                >
                    <span class="icon is-size-4"><i class="fas fa-flag-checkered" /></span>
                </a>
            </div>
            <div
                v-else-if="(this.$props.sessionType === 'results' && (parseInt(this.$props.season) < 2003))"
                class="column is-paddingless has-text-centered"
            >
                <span
                    class="icon is-size-4 has-text-dark"
                >
                    <i class="fas fa-sort-numeric-down" />
                </span>
            </div>
            <div
                v-else-if="(this.$props.sessionType === 'results')"
                class="column is-paddingless has-text-centered"
            >
                <a
                    class="has-text-white"
                    :href="'/qualifying/' + this.$route.params.season + '/' + (parseInt(this.$props.raceID))"
                    title="Qualifying Results"
                >
                    <span class="icon is-size-4"><i class="fas fa-sort-numeric-down" /></span>
                </a>
            </div>
            <div class="column is-paddingless has-text-centered">
                <a
                    class="has-text-white"
                    :href="'/calendar/' + this.$route.params.season"
                    title="Season Calendar"
                >
                    <span class="icon is-size-4"><i class="fas fa-calendar-alt" /></span>
                </a>
            </div>
            <div class="column is-paddingless has-text-centered">
                <span
                    v-if="((parseInt(this.$props.raceID) + 1) > seasonInfo.MRData.total)"
                    class="has-text-dark icon is-size-4"
                    :href="'/' + this.$props.sessionType + '/' + this.$route.params.season + '/' + (parseInt(this.$props.raceID) + 1)"
                    title="Next Race"
                >
                    <i class="fas fa-chevron-circle-right" />
                </span>
                <a
                    v-else
                    class="has-text-white"
                    :href="'/' + this.$props.sessionType + '/' + this.$route.params.season + '/' + (parseInt(this.$props.raceID) + 1)"
                    title="Next Race"
                >
                    <span class="icon is-size-4"><i class="fas fa-chevron-circle-right" />{{ season.MRData }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['season', 'raceID', 'sessionType'],
    computed: {
        seasonInfo () {
            return this.$store.getters.allRaces
        }
    },
    mounted () {
        this.$store.dispatch('getRaceSchedule', this.$props.season)
    }
}
</script>
