<template>
    <section class="section all-races">
        <h2
            :style="{textShadow: '0px 0px 7px rgb(0,0,0)'}"
            class="title is-size-3 has-text-centered is-uppercase has-text-white"
        >
            Formula 1 2023 Season - Session Times
        </h2>
        <!-- <div class="columns has-background-secondary">
            <div class="column has-text-centered is-uppercase has-text-white is-size-6">
                <a class="button is-size-7" :href="'/calendar/' + parseInt(new Date().getFullYear() - 1)">
                    Previous Season
                </a>
            </div>
            <div class="column has-text-centered is-uppercase has-text-white is-size-6">
                <a class="button is-size-7" href="/seasons">
                    All Seasons
                </a>
            </div>
            <div class="column has-text-centered is-uppercase has-text-white is-size-6">
                <a class="button is-size-7" :href="'/standings/' + parseInt(new Date().getFullYear())">
                    Standings
                </a>
            </div>
        </div> -->
        <div class="columns is-multiline is-mobile is-centered">
            <div
                v-for="(race, index) in raceList.dates"
                :key="index"
                class="column is-paddingless is-one-fifth is-race-info is-one-third-tablet is-clipped"
            >
                <div class="race-info-content">
                    <div
                        class="race-flag-bg"
                        :class="{ 'is-generic': race.is_generic }"
                        :style="{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(25, 25, 25, 0.6)), url(/img/FlagKit/' + encodeURI(race.flag_name) + '.svg) no-repeat'}"
                    />
                    <div
                        v-if="(race.is_cancelled == 1)"
                        class="text-container is-centered as-text-white has-text-shadow is-size-4-mobile has-text-centered has-smaller-text-mobile"
                        style="justify-content: flex-start;"
                    >
                        <p class="has-text-white has-text-shadow is-size-4-mobile has-text-centered has-smaller-text-mobile has-text-weight-bold">
                            <a
                                class="has-text-white"
                            >
                                {{ race.race_title }}
                            </a>
                        </p>
                        <p v-html="race.cancelled_text" />
                    </div>
                    <div
                        v-else
                        class="text-container is-centered has-text-white has-text-shadow is-size-4-mobile has-text-centered has-smaller-text-mobile"
                    >
                        <p class="has-text-weight-bold">
                            <a
                                class="has-text-white"
                                :href="'results/2023/' + (race.id + 1)"
                            >
                                {{ race.race_title }}
                            </a>
                        </p>
                        <p v-if="race.fp1_timestamp !== '0000-00-00 00:00:00'">
                            <strong class="has-text-white">FP1: </strong>{{ race.fp1_timestamp | moment("DD/MM/YYYY HH:mm:SS") }}
                        </p>
                        <p v-if="race.fp1_timestamp !== '0000-00-00 00:00:00'">
                            <strong class="has-text-white">FP2: </strong>{{ race.fp2_timestamp | moment("DD/MM/YYYY HH:mm:SS") }}
                        </p>
                        <p><strong class="has-text-white">FP3: </strong>{{ race.fp3_timestamp | moment("DD/MM/YYYY HH:mm:SS") }}</p>
                        <p><strong class="has-text-white">Qual: </strong>{{ race.qual_timestamp | moment("DD/MM/YYYY HH:mm:SS") }}</p>
                        <p><strong class="has-text-white">Race: </strong>{{ race.race_timestamp | moment("DD/MM/YYYY HH:mm:SS") }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data () {
        return {
            raceList: {
                dates: [
                    {
                        id: 0,
                        race_title: 'Bahrain Grand Prix',
                        fp1_timestamp: '2023-03-03 11:30:00',
                        fp2_timestamp: '2023-03-03 15:00:00',
                        fp3_timestamp: '2023-03-04 11:30:00',
                        qual_timestamp: '2023-03-04 15:00:00',
                        race_timestamp: '2023-03-05 15:00:00',
                        country_code: 'BAH',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Bahrain'
                    },
                    {
                        id: 1,
                        race_title: 'Saudi Arabian Grand Prix',
                        fp1_timestamp: '2023-03-17 13:30:00',
                        fp2_timestamp: '2023-03-17 17:00:00',
                        fp3_timestamp: '2023-03-18 13:30:00',
                        qual_timestamp: '2023-03-18 17:00:00',
                        race_timestamp: '2023-03-19 17:00:00',
                        country_code: 'Sau',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Saudi Arabia'
                    },
                    {
                        id: 2,
                        race_title: 'Australian Grand Prix',
                        fp1_timestamp: '2023-03-31 02:30:00',
                        fp2_timestamp: '2023-03-31 06:00:00',
                        fp3_timestamp: '2023-04-01 02:30:00',
                        qual_timestamp: '2023-04-01 06:00:00',
                        race_timestamp: '2023-04-02 06:00:00',
                        country_code: 'AUS',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Australia'
                    },

                    {
                        id: null,
                        race_title: 'Chinese Grand Prix',
                        fp1_timestamp: '2022-09-22 09:30:00',
                        fp2_timestamp: '2022-09-22 13:00:00',
                        fp3_timestamp: '2022-09-23 10:00:00',
                        qual_timestamp: '2022-09-23 13:00:00',
                        race_timestamp: '2022-09-24 13:00:00',
                        country_code: 'CHI',
                        active: 0,
                        is_cancelled: 1,
                        cancelled_text: "<p>Cancelled due to China's continuing restrictions for foreign visitors during the COVID-19 Pandemic. Replacement TBC</p>",
                        flag_name: 'China'
                    },
                    {
                        id: 3,
                        race_title: 'Azerbaijan Grand Prix',
                        fp1_timestamp: '2023-04-28 10:30:00',
                        fp2_timestamp: '2023-04-28 14:00:00',
                        fp3_timestamp: '2023-04-29 10:30:00',
                        qual_timestamp: '2023-04-29 14:30:00',
                        race_timestamp: '2023-04-30 12:00:00',
                        country_code: 'AZE',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Azerbaijan'
                    },
                    {
                        id: 4,
                        race_title: 'Miami Grand Prix',
                        fp1_timestamp: '2023-05-05 18:30:00',
                        fp2_timestamp: '2023-05-05 22:00:00',
                        fp3_timestamp: '2023-05-07 17:30:00',
                        qual_timestamp: '2023-05-07 21:00:00',
                        race_timestamp: '2023-05-08 20:30:00',
                        country_code: 'MIA',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'USA'
                    },
                    {
                        id: null,
                        race_title: 'Emilia Romagna Grand Prix',
                        fp1_timestamp: '2023-05-19 12:30:00',
                        fp2_timestamp: '2023-05-19 16:00:00',
                        fp3_timestamp: '2023-05-20 11:30:00',
                        qual_timestamp: '2023-05-20 15:00:00',
                        race_timestamp: '2023-05-21 14:00:00',
                        country_code: 'IMO',
                        active: 0,
                        is_cancelled: 1,
                        cancelled_text: '<p>Cancelled due to severe rainfall and flooding in the greater Emilia Romagna region</p>',
                        flag_name: 'Italy'
                    },
                    {
                        id: 5,
                        race_title: 'Monaco Grand Prix',
                        fp1_timestamp: '2023-05-26 12:30:00',
                        fp2_timestamp: '2023-05-26 16:00:00',
                        fp3_timestamp: '2023-05-27 11:30:00',
                        qual_timestamp: '2023-05-27 15:00:00',
                        race_timestamp: '2023-05-28 14:00:00',
                        country_code: 'MON',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Monaco'
                    },
                    {
                        id: 6,
                        race_title: 'Spanish Grand Prix',
                        fp1_timestamp: '2023-06-02 12:30:00',
                        fp2_timestamp: '2023-06-02 16:00:00',
                        fp3_timestamp: '2023-06-03 11:30:00',
                        qual_timestamp: '2023-06-03 15:00:00',
                        race_timestamp: '2023-06-04 14:00:00',
                        country_code: 'SPA',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Spain'
                    },
                    {
                        id: 7,
                        race_title: 'Canadian Grand Prix',
                        fp1_timestamp: '2023-06-16 18:30:00',
                        fp2_timestamp: '2023-06-16 22:00:00',
                        fp3_timestamp: '2023-06-11 17:30:00',
                        qual_timestamp: '2023-06-11 21:00:00',
                        race_timestamp: '2023-06-12 19:00:00',
                        country_code: 'CAB',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Canada'
                    },
                    {
                        id: 8,
                        race_title: 'Austrian Grand Prix',
                        fp1_timestamp: '2023-06-30 12:30:00',
                        fp2_timestamp: '2023-06-30 16:00:00',
                        fp3_timestamp: '2023-07-01 11:30:00',
                        qual_timestamp: '2023-07-01 15:30:00',
                        race_timestamp: '2023-07-02 14:00:00',
                        country_code: 'AUT',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Austria'
                    },
                    {
                        id: 9,
                        race_title: 'British Grand Prix',
                        fp1_timestamp: '2023-07-07 12:30:00',
                        fp2_timestamp: '2023-07-07 16:00:00',
                        fp3_timestamp: '2023-07-08 11:30:00',
                        qual_timestamp: '2023-07-08 15:00:00',
                        race_timestamp: '2023-07-09 15:00:00',
                        country_code: 'GBR',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'UK'
                    },
                    {
                        id: 10,
                        race_title: 'Hungarian Grand Prix',
                        fp1_timestamp: '2023-07-21 12:30:00',
                        fp2_timestamp: '2023-07-21 16:00:00',
                        fp3_timestamp: '2023-07-22 11:30:00',
                        qual_timestamp: '2023-07-22 15:00:00',
                        race_timestamp: '2023-07-23 14:00:00',
                        country_code: 'HUN',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Hungary'
                    },
                    {
                        id: 11,
                        race_title: 'Belgian Grand Prix',
                        fp1_timestamp: '2023-07-18 12:30:00',
                        fp2_timestamp: '2023-07-28 16:00:00',
                        fp3_timestamp: '2023-07-29 11:30:00',
                        qual_timestamp: '2023-07-29 15:30:00',
                        race_timestamp: '2023-07-30 14:00:00',
                        country_code: 'BEL',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Belgium'
                    },
                    {
                        id: 12,
                        race_title: 'Dutch Grand Prix',
                        fp1_timestamp: '2023-08-25 11:30:00',
                        fp2_timestamp: '2023-08-25 15:00:00',
                        fp3_timestamp: '2023-08-26 10:30:00',
                        qual_timestamp: '2023-08-26 14:00:00',
                        race_timestamp: '2023-08-27 14:00:00',
                        country_code: 'NED',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Netherlands'
                    },
                    {
                        id: 13,
                        race_title: 'Italian Grand Prix',
                        fp1_timestamp: '2023-09-01 12:30:00',
                        fp2_timestamp: '2023-09-01 16:00:00',
                        fp3_timestamp: '2023-09-02 11:30:00',
                        qual_timestamp: '2023-09-02 14:00:00',
                        race_timestamp: '2023-09-03 13:00:00',
                        country_code: 'ITA',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Italy'
                    },
                    {
                        id: 14,
                        race_title: 'Singapore Grand Prix',
                        fp1_timestamp: '2023-09-15 10:30:00',
                        fp2_timestamp: '2023-09-15 14:00:00',
                        fp3_timestamp: '2023-09-16 10:30:00',
                        qual_timestamp: '2023-09-16 14:00:00',
                        race_timestamp: '2023-09-17 13:00:00',
                        country_code: 'SIN',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Singapore'
                    },
                    {
                        id: 15,
                        race_title: 'Japanese Grand Prix',
                        fp1_timestamp: '2023-09-22 03:30:00',
                        fp2_timestamp: '2023-09-22 07:00:00',
                        fp3_timestamp: '2023-09-23 03:30:00',
                        qual_timestamp: '2023-09-23 07:00:00',
                        race_timestamp: '2023-09-24 06:00:00',
                        country_code: 'JAP',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Japan'
                    },
                    {
                        id: 16,
                        race_title: 'Qatar Grand Prix',
                        fp1_timestamp: '2023-10-06 11:30:00',
                        fp2_timestamp: '2023-10-06 15:00:00',
                        fp3_timestamp: '2023-10-07 11:30:00',
                        qual_timestamp: '2023-10-07 15:30:00',
                        race_timestamp: '2023-10-08 15:00:00',
                        country_code: 'QAT',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Qatar'
                    },
                    {
                        id: 17,
                        race_title: 'United States Grand Prix',
                        fp1_timestamp: '2023-10-20 18:30:00',
                        fp2_timestamp: '2023-10-20 22:00:00',
                        fp3_timestamp: '2023-10-21 19:00:00',
                        qual_timestamp: '2023-10-21 23:00:00',
                        race_timestamp: '2023-10-22 20:00:00',
                        country_code: 'USA',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'USA'
                    },
                    {
                        id: 18,
                        race_title: 'Mexico City Grand Prix',
                        fp1_timestamp: '2023-10-27 19:30:00',
                        fp2_timestamp: '2023-10-27 23:00:00',
                        fp3_timestamp: '2023-10-28 18:30:00',
                        qual_timestamp: '2023-10-28 22:00:00',
                        race_timestamp: '2023-10-29 20:00:00',
                        country_code: 'MEX',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Mexico'
                    },
                    {
                        id: 19,
                        race_title: 'Brazilian Grand Prix',
                        fp1_timestamp: '2023-11-03 14:30:00',
                        fp2_timestamp: '2023-11-03 18:00:00',
                        fp3_timestamp: '2023-11-04 14:30:00',
                        qual_timestamp: '2023-11-04 18:30:00',
                        race_timestamp: '2023-11-05 17:00:00',
                        country_code: 'USA',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'Brazil'
                    },
                    {
                        id: 20,
                        race_title: 'Las Vegas Grand Prix',
                        fp1_timestamp: '2023-11-17 02:30:00',
                        fp2_timestamp: '2023-11-17 06:00:00',
                        fp3_timestamp: '2023-11-18 02:30:00',
                        qual_timestamp: '2023-11-18 06:00:00',
                        race_timestamp: '2023-11-19 06:00:00',
                        country_code: 'USA',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'USA'
                    },
                    {
                        id: 21,
                        race_title: 'Abu Dhabi Grand Prix',
                        fp1_timestamp: '2023-11-24 09:30:00',
                        fp2_timestamp: '2023-11-24 13:00:00',
                        fp3_timestamp: '2023-11-25 10:30:00',
                        qual_timestamp: '2023-11-25 14:00:00',
                        race_timestamp: '2023-11-26 13:00:00',
                        country_code: 'ABU',
                        active: 0,
                        is_cancelled: 0,
                        cancelled_text: '',
                        flag_name: 'UAE'
                    }
                ]
            }
        }
    }
}
</script>
