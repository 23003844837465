<template>
    <div class="is-background">
        <div class="container">
            <QualiDetail />
        </div>
    </div>
</template>

<script>
import QualiDetail from '@/components/QualiDetail'

export default {
    name: 'QualifyingDetail',
    components: {
        QualiDetail
    },
    mounted () {
        this.background = this.$el.getAttribute('data-image')
    }
}
</script>
