<template>
    <div
        :id="' podiumResults round--' + parseInt(raceID + 1)"
    >
        <p
            v-for="(result, index) in results.MRData.RaceTable.Races[raceID].Results.slice(0,3)"
            :key="index"
            :class="'pos-icon position-' + result.position"
            class="has-text-white has-text-shadow is-size-7 is-size-4-mobile has-smaller-text-mobile"
        >
            <span class="icon">
                <i class="fas fa-medal" />
            </span>
            {{ result.Driver.givenName }} {{ result.Driver.familyName }}
        </p>
        <div class="columns pt-3 has-text-centered">
            <div class="column">
                <a
                    class="button is-size-7"
                    style="text-shadow:none;"
                    :href="'/results/' + season + '/' + (raceID + 1)"
                >
                    Results
                </a>
            </div>
            <div class="column">
                <a
                    class="button is-size-7"
                    style="text-shadow:none;"
                    :href="'/qualifying/' + parseInt(season) + '/' + (raceID + 1)"
                >
                    Qualifying
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['raceID', 'results', 'season']
}
</script>
