<template>
    <div class="container">
        <SeasonCalendar :season="this.$attrs.season" />
    </div>
</template>

<script>
import SeasonCalendar from '@/components/SeasonCalendar'

export default {
    name: 'Home',
    components: {
        SeasonCalendar
    }
}
</script>
