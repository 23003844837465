<template>
    <section class="hero is-fullheight">
        <div class="container">
            <SeasonList />
        </div>
        <div class="hero-foot">
            <nav
                id="navbarMain"
                class="navbar is-fixed-bottom"
            >
                <div class="navbar-brand">
                    <div
                        class="navbar-burger burger"
                        data-target="navbarExample"
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
                <div
                    id="navbarMain"
                    class="navbar-menu"
                >
                    <div class="navbar-center has-background-secondary">
                        <a
                            class="navbar-item"
                            href="/"
                        >
                            Home
                        </a>
                        <a
                            class="navbar-item"
                            href="/calendar"
                        >
                            Current Season
                        </a>
                        <a
                            class="navbar-item"
                            href="/about"
                        >
                            About
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>
import SeasonList from '@/components/SeasonList'
export default {
    components: {
        SeasonList
    }
}
</script>
