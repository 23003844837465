<template>
    <section class="hero is-fullheight all-races">
        <div class="container">
            <h2
                :style="{textShadow: '0px 0px 7px rgb(0,0,0)'}"
                class="title is-size-3 has-text-centered is-uppercase has-text-white"
            >
                Formula 1 {{ this.$props.season }} Season
            </h2>
            <div
                v-if="this.$route.params.season <= new Date().getFullYear()"
                class="columns is-multiline is-mobile is-centered mb-3"
            >
                <div
                    v-for="(race, index) in races.MRData.RaceTable.Races"
                    :key="index"
                    class="column is-paddingless is-one-fifth is-race-info is-one-third-tablet is-clipped"
                >
                    <div class="race-info-content">
                        <div
                            class="race-flag-bg"
                            :style="{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(35, 35, 35, 0.6)), url(/img/FlagKit/' + encodeURI(race.Circuit.Location.country) + '.svg) no-repeat'}"
                        />
                        <div class="text-container is-centered">
                            <p class="has-text-white has-text-shadow is-size-7 is-size-4-mobile has-text-centered has-smaller-text-mobile has-text-weight-bold">
                                <a
                                    class="has-text-white"
                                    :href="'/results/' + race.season + '/' + (index + 1)"
                                >
                                    {{ race.raceName }}
                                </a>
                            </p>
                            <p class="has-text-white has-text-shadow is-size-7 is-size-4-mobile has-text-centered has-smaller-text-mobile">
                                {{ race.date | moment("dddd, MMMM Do YYYY") }}
                            </p>
                            <p class="has-text-white has-text-shadow is-size-7 is-size-4-mobile has-text-centered has-smaller-text-mobile">
                                {{ race.Circuit.circuitName }}
                            </p>
                            <PodiumResults
                                :race-i-d="index"
                                :results="results"
                                :season="race.season"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="columns is-centered"
            >
                <div class="column is-full has-background-secondary is-centered">
                    <p v-if="this.$route.params.season > new Date().getFullYear()">
                        A season calendar has not been published for the {{ this.$route.params.season }} season.
                    </p>
                </div>
            </div>
        </div>
        <div class="hero-foot">
            <nav
                id="navbarMain"
                class="navbar is-fixed-bottom"
            >
                <div
                    id="navbarMain"
                    class="navbar-menu"
                >
                    <div class="navbar-center has-background-secondary">
                        <a
                            v-if="(parseInt(this.$route.params.season - 1) > 1949)"
                            class="navbar-item"
                            :href="'/calendar/' + parseInt(this.$route.params.season -1)"
                        >
                            Previous Season
                        </a>
                        <a
                            class="navbar-item"
                            href="/"
                        >
                            Home
                        </a>
                        <a
                            class="navbar-item"
                            href="/seasons"
                        >
                            All Seasons
                        </a>
                        <a
                            class="navbar-item"
                            :href="'/standings/' + this.$route.params.season"
                        >
                            Standings
                        </a>
                        <a
                            class="navbar-item"
                            href="/about"
                        >
                            About
                        </a>
                        <a
                            v-if="(parseInt(this.$route.params.season) + 1 <= new Date().getFullYear())"
                            class="navbar-item"
                            :href="'/calendar/' + parseInt(parseInt(this.$route.params.season) + 1)"
                        >
                            Next Season
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>
import PodiumResults from '@/components/PodiumResults'
export default {
    components: {
        PodiumResults
    },
    props: {
        season: {
            type: String,
            default: '2023'
        }
    },
    computed: {
        races () {
            return this.$store.getters.allRaces
        },
        results () {
            return this.$store.getters.seasonResults
        }
    },
    mounted () {
        this.$store.dispatch('getRaceSchedule', this.$props.season)
        this.$store.dispatch('getSeasonResults', this.$props.season)
    }
}
</script>
