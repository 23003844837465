<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-multiline">
                    <div class="column mb-3 is-full">
                        <DriversStandings
                            :season="this.$attrs.season"
                            :show-all="false"
                        />
                    </div>
                    <div
                        v-if="parseInt(this.$attrs.season) >= 1958"
                        class="column mb-3 is-full"
                    >
                        <ConstructorsStandings
                            :season="this.$attrs.season"
                        />
                    </div>
                </div>
            </div>
        </div>
        <nav
            id="navbarMain"
            class="navbar is-fixed-bottom"
        >
            <div class="navbar-brand">
                <div
                    class="navbar-burger burger"
                    data-target="navbarExample"
                >
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div
                id="navbarMain"
                class="navbar-menu"
            >
                <div class="navbar-center has-background-secondary">
                    <a
                        class="navbar-item"
                        href="/"
                    >
                        Home
                    </a>
                    <a
                        class="navbar-item"
                        href="/seasons"
                    >
                        All Seasons
                    </a>
                    <a
                        class="navbar-item"
                        :href="'/calendar/' + this.$route.params.season"
                    >
                        Season Overview
                    </a>
                    <a
                        class="navbar-item"
                        href="/about"
                    >
                        About
                    </a>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
import DriversStandings from '@/components/SimpleDriversStandings'
import ConstructorsStandings from '@/components/SimpleConstructorsStandings'
export default {
    components: {
        DriversStandings,
        ConstructorsStandings
    }
}
</script>
