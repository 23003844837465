<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            showNav: false
        }
    }
}
</script>
