import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'
import Index from './views/Index.vue'
import About from './views/About.vue'
import RaceDetail from './views/RaceDetail.vue'
import SeasonCalendar from './views/SeasonCalendar.vue'
import CombinedStandings from './components/standings/CombinedStandings.vue'
import SeasonList from './views/SeasonList.vue'
import QualiDetail from './views/QualiDetail.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Index,
            props: { season: '2023' }
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/calendar',
            name: 'currentSeason',
            component: Index,
            props: { season: '2023' }
        },
        {
            path: '/calendar/:season',
            name: 'calendar',
            component: SeasonCalendar,
            props: true
        },
        {
            path: '/standings',
            name: 'currentStandings',
            component: CombinedStandings
        },
        {
            path: '/standings/:season',
            name: 'standings',
            component: CombinedStandings,
            props: true
        },
        {
            path: '/results/:season/:raceID',
            name: 'raceDetail',
            component: RaceDetail
        },
        {
            path: '/qualifying/:season/:raceID',
            name: 'qualiDetail',
            component: QualiDetail
        },
        {
            path: '/seasons',
            name: 'seasonList',
            component: SeasonList
        }
    ]
})
