<template>
    <section class="hero is-fullheight">
        <div class="container">
            <RaceList />
        </div>
        <div class="hero-foot">
            <nav
                id="navbarMain"
                class="navbar is-fixed-bottom"
            >
                <div
                    id="navbarMain"
                    class="navbar-menu"
                >
                    <div class="navbar-center has-background-secondary">
                        <a
                            class="navbar-item"
                            :href="'/calendar/' + parseInt(this.$attrs.season - 1)"
                        >
                            Previous Season
                        </a>
                        <a
                            class="navbar-item"
                            :href="'/standings/' + this.$attrs.season"
                        >
                            Standings
                        </a>
                        <a
                            class="navbar-item"
                            href="/seasons"
                        >
                            All Seasons
                        </a>
                        <a
                            class="navbar-item"
                            href="/about"
                        >
                            About
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>
import RaceList from '@/components/RaceList'

export default {
    name: 'Index',
    components: {
        RaceList
    },
    computed: {}
}
</script>
