import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        races: [],
        currentRace: [],
        driversStandings: [],
        constructorsStandings: [],
        seasonResults: [],
        fastestLaps: [],
        driverResults: [],
        nextRace: [],
        seasonList: [],
        currentQuali: []
    },
    getters: {
        allRaces: state => state.races,
        selectedRace: state => state.currentRace,
        driversStandings: state => state.driversStandings,
        constructorsStandings: state => state.constructorsStandings,
        seasonResults: state => state.seasonResults,
        fastestLaps: state => state.fastestLaps,
        driverResults: state => state.driverResults,
        nextRace: state => state.nextRace,
        seasonList: state => state.seasonList,
        selectedQuali: state => state.currentQuali
    },
    mutations: {
        GET_RACES: (state, races) => {
            state.races = races
            console.log(races)
        },
        SET_RACE: (state, race) => {
            state.currentRace = race
        },
        SET_DRIVERS_STANDINGS: (state, driversStandings) => {
            state.driversStandings = driversStandings
        },
        SET_CONSTRUCTORS_STANDINGS: (state, constructorsStandings) => {
            state.constructorsStandings = constructorsStandings
        },
        GET_SEASON_RESULTS: (state, seasonResults) => {
            state.seasonResults = seasonResults
        },
        GET_DRIVER_RESULTS: (state, driverResults) => {
            state.driverResults = driverResults
        },
        NEXT_RACE: (state, nextRace) => {
            state.nextRace = nextRace
        },
        SET_SEASON_LIST: (state, seasonList) => {
            state.seasonList = seasonList
        },
        SET_QUALI: (state, quali) => {
            state.currentQuali = quali
        }
    },
    actions: {
        getSeasonResults ({ commit }, season) {
            axios.get('https://ergast.com/api/f1/' + season + '/results.json?limit=999').then(response => {
                commit('GET_SEASON_RESULTS', response.data)
            }).catch(error => { console.log(error) })
        },
        getRaceSchedule ({ commit }, season) {
            axios.get('https://ergast.com/api/f1/' + season + '.json').then(response => {
                commit('GET_RACES', response.data)
            }).catch(error => { console.log(error) })
        },
        getSelectedRace ({ commit, state }, { season, raceID }) {
            console.log(season + '/' + raceID)
            axios.get('https://ergast.com/api/f1/' + season + '/' + raceID + '/results.json').then(response => {
                commit('SET_RACE', response.data)
            }).catch(error => { console.log(error) })
        },
        getSelectedQuali ({ commit, state }, { season, raceID }) {
            console.log(season + '/' + raceID)
            axios.get('https://ergast.com/api/f1/' + season + '/' + raceID + '/qualifying.json').then(response => {
                commit('SET_QUALI', response.data)
            }).catch(error => { console.log(error) })
        },
        getDriversStandings ({ commit, state }, season) {
            axios.get('https://ergast.com/api/f1/' + season + '/driverStandings.json?limit=50').then(response => {
                commit('SET_DRIVERS_STANDINGS', response.data)
            }).catch(error => { console.log(error) })
        },
        getConstructorsStandings ({ commit, state }, season) {
            axios.get('https://ergast.com/api/f1/' + season + '/constructorStandings.json?limit=50').then(response => {
                commit('SET_CONSTRUCTORS_STANDINGS', response.data)
            }).catch(error => { console.log(error) })
        },
        getSeasonList ({ commit, state }) {
            axios.get('https://ergast.com/api/f1/seasons.json?limit=100').then(response => {
                commit('SET_SEASON_LIST', response.data)
            }).catch(error => { console.log(error) })
        }
    }
})
