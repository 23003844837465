<template>
    <div class="column">
        <div class="columns is-marginless is-centered">
            <div class="column is-full has-background-secondary has-text-centered">
                FORMULA 1 {{ this.$route.params.season }} SEASON - CONSTRUCTORS STANDINGS
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-full is-gapless">
                <div
                    v-for="(constructor, index) in constructors.MRData.StandingsTable.StandingsLists[0].ConstructorStandings"
                    :key="index"
                    class="columns is-standings is-mobile has-background-primary"
                >
                    <div
                        v-if="constructor.positionText === 'E'"
                        class="column is-position-box is-1 is-paddingless has-text-centered"
                    >
                        EX
                    </div>
                    <div
                        v-else
                        class="column is-position-box is-1 is-paddingless has-text-centered has-background-white has-text-dark"
                    >
                        {{ constructor.positionText }}
                    </div>
                    <div
                        class="column is-1 is-paddingless is-flag has-text-right"
                        :style="{ width: '30px'}"
                    >
                        <img
                            :src="'../../img/Flagkit/drivers/' + constructor.Constructor.nationality + '.svg'"
                            :alt="constructor.Constructor.nationality"
                        >
                    </div>
                    <div class="column is-paddingless is-padded-left">
                        {{ constructor.Constructor.name }}
                    </div>
                    <div class="column is-1 is-paddingless">
                        {{ constructor.points }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        season: {
            type: String,
            default: '2019'
        }
    },
    computed: {
        constructors () {
            return this.$store.getters.constructorsStandings
        }
    },
    mounted () {
        this.$store.dispatch('getConstructorsStandings', this.$props.season)
    }
}
</script>
