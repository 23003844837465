<template>
    <section class="section">
        <div class="columns is-multiline is-mobile is-centered">
            <div
                v-for="(season, index) in seasons.MRData.SeasonTable.Seasons"
                :key="index"
                class="column is-1 is-one-third-tablet is-half-mobile is-season-link"
                :class="{'has-background-primary': index % 2 === 0, 'has-background-secondary': index % 2 !== 0 }"
                :style="{paddingTop: '1rem', paddingBottom: '1rem'}"
            >
                <p class="has-text-white has-text-centered has-text-weight-bold">
                    <a
                        class="is-size-5 has-text-white has-text-underline"
                        :href="'/calendar/' + (season.season)"
                    >
                        {{ season.season }}
                    </a>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        seasons () {
            return this.$store.getters.seasonList
        }
    },
    mounted () {
        this.$store.dispatch('getSeasonList')
    }
}
</script>
