<template>
    <div
        class="is-background"
        data-image="https://cdn.sankex.uk/img/test.jpg"
    >
        <div class="container">
            <RaceDetail />
        </div>
    </div>
</template>

<script>
import RaceDetail from '@/components/RaceDetail'

export default {
    name: 'Home',
    components: {
        RaceDetail
    },
    mounted () {
        this.background = this.$el.getAttribute('data-image')
    }
}
</script>
